import { render, staticRenderFns } from "./alertbar.vue?vue&type=template&id=3945a3ce&scoped=true"
import script from "./alertbar.vue?vue&type=script&lang=js"
export * from "./alertbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3945a3ce",
  null
  
)

export default component.exports